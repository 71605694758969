<template><v-app><Header /><v-main><v-container fluid>
<v-row dense><v-col cols="12"><v-card>
    <v-card-title class="text-h5 green text--info white--text">{{ $t('Edit Profile saya') }}</v-card-title>
    <v-card-subtitle class="green white--text">{{ $t('Edit Profile saya sendiri') }}</v-card-subtitle>
    <v-card-text class="text--primary mt-2">
    
    <v-row>
        <v-col cols="12" md="3">
            <v-row class="justify-center"><v-col cols="12">
                <v-card>
                    <v-card-title class="text-h5 info text--info white--text">{{ $t('Gambar profile') }}</v-card-title>
                    <v-card-text class="mt-2">
                        <v-row><v-col cols="12" v-show="!cameraon">
                        <v-img :lazy-src="nophoto" :src="datatables.photoUrl" max></v-img>
                        
                        <v-snackbar v-model="snackbar" color="blue">
                            {{ snackbartext }}
                            <template v-slot:action="{ attrs }">
                                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">X</v-btn>
                            </template>
                        </v-snackbar>
                        
                        </v-col></v-row>
                        <v-row><v-col cols="12" v-show="cameraon">
                        <vue-web-cam
                                ref="webcam"
                                :device-id="deviceId"
                                width="100%"
                                @started="onStarted"
                                @stopped="onStopped"
                                @error="onError"
                                @cameras="onCameras"
                                @camera-change="onCameraChange"
                            />
                        </v-col></v-row>
                        <input type="file" ref="imgupload" accept="image/*" @change="setImage($event)" style="display:none"/> 
                        <v-row v-show="cameraon && !uploadReady && !ajaxProcess">
                            <v-col cols="12" class="text-center"><v-btn @click="onStart()" elevation="2" class="success mr-4"><v-icon class="mr-2">fa-thumbs-up</v-icon>OK</v-btn></v-col>
                        </v-row>
                        <v-row v-show="!cameraon && !uploadReady&& !ajaxProcess">
                            <v-col cols="6" class="text-center"><v-btn @click="onStart()" elevation="2" class="info mr-4"><v-icon class="mr-2">fa-camera</v-icon> {{ $t('Camera') }}</v-btn></v-col>
                            <v-col cols="6" class="text-center"><v-btn @click="browseImage()" elevation="2" class="info"><v-icon class="mr-2">fa-image</v-icon> {{ $t('Galery') }}</v-btn></v-col>
                        </v-row>
                        <v-row v-show="uploadReady && !ajaxProcess">
                            <v-col cols="12" class="text-center"><v-btn large @click="uploadImage()" elevation="2" class="success"><v-icon class="mr-2">fa-upload</v-icon> {{ $t('Upload') }}</v-btn></v-col>
                        </v-row>
                        <v-row v-show="ajaxProcess"><v-col cols="12">
                        <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6" />
                        </v-col></v-row>
                    </v-card-text>
                </v-card>
            </v-col></v-row>
        </v-col>
        
        <v-col cols="12" md="9">
            <v-form v-model="validProfile" ref="formProfile"><v-row><v-col cols="12">
                <v-card>
                    <v-card-title class="text-h5 info text--info white--text">{{ $t('Data profile') }}</v-card-title>
                    <v-card-text class="mt-2">
                    <v-row>
                        <v-col cols="12" md="6"><v-text-field :label="$t('Member Num.')" prepend-icon="fa-user" type="text" autocomplete="new-user" required  :rules="rules.isRequired" v-model="noanggota"/></v-col>
                        <v-col cols="12" md="6"><v-text-field :label="$t('Full name')" prepend-icon="fa-check-circle" type="text" required  :rules="rules.isRequired" v-model="fullname"/></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6"><v-text-field prepend-icon="fa-birthday-cake" :label="$t('Birth address')" v-model="birthaddress" /></v-col>
                        <v-col cols="12" md="6">
                            <v-menu v-model="tgllahir.tglcheck" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="tgllahir.text"
                                    :label="$t('Birth date')"
                                    prepend-icon="mdi-calendar-text"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker v-model="tgllahir.val" @input="tgllahir.tglcheck = false" :allowed-dates="allowedDates" />
                            </v-menu>
                        </v-col>            
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6"><v-textarea prepend-icon="fa-map-marker" rows="2" :label="$t('Address')" v-model="address" /></v-col>
                        <v-col cols="12" md="6"><v-text-field :label="$t('Kota tinggal')" prepend-icon="fa-map" type="text" v-model="datatables.city"/></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6"><v-text-field :label="$t('Provinsi tinggal')" prepend-icon="fa-map" type="text" v-model="datatables.province"/></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6"><v-text-field :label="$t('Nomor hp')" prepend-icon="fa-phone" type="text" v-model="hp"/></v-col>
                        <v-col cols="12" md="6"><v-text-field :label="$t('Nomor telepon')" prepend-icon="fa-phone" type="text" v-model="datatables.phone"/></v-col>
                    </v-row>            
                    <v-row>
                        <v-col cols="12" md="6"> <v-select
                            prepend-icon="fa-check-circle"
                        v-model="identityOptionValue"
                        :items="identityoptions"
                        item-text="nama"
                        item-value="id"
                        :label="$t('Identity type')"
                        auto-select-first="true"
                        /></v-col>
                        <v-col cols="12" md="6"><v-text-field prepend-icon="fa-check-circle" :label="$t('Identity number')" v-model="nomoridentitas" /></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6"><v-select
                            prepend-icon="fa-check-circle"
                        v-model="sexOptionValue"
                        :items="sexoptions"
                        item-text="nama"
                        item-value="id"
                        :label="$t('Jenis kelamin')"
                        auto-select-first="true"
                        />
                        </v-col>
                        <v-col cols="12" md="6"><v-select
                            prepend-icon="fa-check-circle"
                        v-model="pekerjaanOptionValue"
                        :items="pekerjaanoptions"
                        item-text="pekerjaan"
                        item-value="id"
                        :label="$t('Job type')"
                        auto-select-first="true"
                        /></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                            prepend-icon="fa-check-circle"
                        v-model="agamaOptionValue"
                        :items="agamaoptions"
                        item-text="nama"
                        item-value="id"
                        :label="$t('Agama')"
                        auto-select-first="true"
                        />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                            prepend-icon="fa-check-circle"
                        v-model="pendidikanOptionValue"
                        :items="pendidikanoptions"
                        item-text="nama"
                        item-value="id"
                        :label="$t('Pendidikan')"
                        auto-select-first="true"
                        />
                        </v-col>
                    </v-row>
                    <v-row v-show="ajaxProcess"><v-col cols="12">
                        <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6" />
                        </v-col></v-row>
                    <v-row v-show="profile_msg_error.length > 0"><v-col cols="12">
                        <v-alert border="top" color="red lighten-2" dark>{{ profile_msg_error }}</v-alert>
                        </v-col></v-row>
                    <v-row v-show="!ajaxProcess">
                        <v-col cols="12" class="d-flex justify-end"><v-btn class="success" @click="saveprofile()"><v-icon class="mr-2">fa-save</v-icon> {{ $t('Simpan profile') }}</v-btn></v-col>
                    </v-row>
                            </v-card-text>
                        </v-card>
                </v-col></v-row></v-form>
                
                <v-form v-model="validPassword" ref="formPassword"><v-row><v-col cols="12">
                    <v-card>
                        <v-card-title class="text-h5 info text--info white--text">{{ $t('Ganti password') }}</v-card-title>
                        <v-card-text class="mt-2">
                            <v-row>
                                <v-col cols="12" md="6"><v-text-field label="Password" prepend-icon="fa-key" autocomplete="new-password" :rules="rules.isRequired"
                                            ref="passwordText" v-model="pass" :append-icon="iconpassword" @click:append="passwordeye" :type="typepasswordnya" ></v-text-field></v-col>
                                <v-col cols="12" md="6"><v-text-field label="Re-Password" prepend-icon="fa-key" autocomplete="new-password" :rules="rules.isRequired"
                                            ref="passwordText" v-model="pass2" :append-icon="iconpassword2" @click:append="passwordeye2" :type="typepasswordnya2" ></v-text-field></v-col>
                            </v-row>
                            <v-row v-show="ajaxProcess"><v-col cols="12">
                            <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6" />
                            </v-col></v-row>
                            <v-row v-show="password_msg_error.length > 0"><v-col cols="12">
                            <v-alert border="top" color="red lighten-2" dark>{{ password_msg_error }}</v-alert>
                            </v-col></v-row>
                            <v-row v-show="!ajaxProcess">
                                <v-col cols="12" class="d-flex justify-end"><v-btn class="success" @click="simpanPassword()"><v-icon class="mr-2">fa-save</v-icon> {{ $t('Simpan password') }}</v-btn></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col></v-row></v-form>
            </v-col>
        </v-row>
    </v-card-text>
</v-card></v-col></v-row>
</v-container></v-main><Footer />
</v-app></template>
<script>
const sha1 = require('sha1');
import Header from './layout/Header';
import Footer from './layout/Footer';
import { WebCam } from "vue-web-cam";

  export default {
    components: { Header, Footer, 'vue-web-cam': WebCam },
    data: vm => ({
        ajaxProcess:true,
        datatables:null,
        nophoto:process.env.VUE_APP_NO_PHOTO,
        
        cameraon:false,img: null,camera: null, deviceId: null,devices: [], uploadReady:false, fileUploadImage:null,
        
        snackbartext:'',snackbar:false,
        
        noanggota: '',fullname:'',hp:'',address:'',birthaddress:'',nomoridentitas:'',
        tgllahir: {val:null,text:null,tglcheck:false},allowedDates:false,
        identityoptions:[],identityOptionValue:0,
        pekerjaanoptions:[],pekerjaanOptionValue:0,
        agamaoptions:[],agamaOptionValue:0,
        pendidikanoptions:[],pendidikanOptionValue:0,
        sexoptions:[],sexOptionValue:0,profile_msg_error:'',
        validProfile:false,
        
        pass:'',typepasswordnya:'password',iconpassword:'fa-eye-slash',
        pass2:'',typepasswordnya2:'password',iconpassword2:'fa-eye-slash',
        validPassword:false,password_msg_error:'',
    
        rules: {isRequired: [
            v => !!v || vm.$t('Is Required')
        ]}
    }),
    methods: {
        refreshTable() {
            var vue = this;
            if (vue.ajaxProcess) {
                return;
            }
            
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };
                        
            vue.ajaxProcess = true;
            fetch(process.env.VUE_APP_WEB_API + 'api/profile', requestOptions)
            .then(async response => {
                vue.datatables = await response.json();
                
                vue.noanggota = vue.datatables.memberno;
                vue.fullname = vue.datatables.fullname;
                vue.hp = vue.datatables.noHp;
                vue.address = vue.datatables.address;
                vue.birthaddress = vue.datatables.placeOfBirth;
                vue.nomoridentitas = vue.datatables.identityNo;
                
                if (vue.datatables.identityId > -1) {
                    vue.identityOptionValue = vue.datatables.identityId;
                }
                
                if (vue.datatables.jobId > -1) {
                    vue.pekerjaanOptionValue = vue.datatables.jobId;
                }
                
                if (vue.datatables.jenis_kelamin_id > -1) {
                    vue.sexOptionValue = vue.datatables.jenis_kelamin_id;
                }
                
                if (vue.datatables.agamaId > -1) {
                    vue.agamaOptionValue = vue.datatables.agamaId;
                }
                if (vue.datatables.pendidikanId > -1) {
                    vue.pendidikanOptionValue = vue.datatables.pendidikanId;
                }
                
                vue.tgllahir.val = vue.datatables.dateOfBirthData;
                vue.tgllahir.text = vue.$parent.formatDate(vue.datatables.dateOfBirthData);
                
                vue.ajaxProcess = false;
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStart() {
            if (this.cameraon) {
                this.datatables.photoUrl = this.$refs.webcam.capture();
                this.$refs.webcam.stop();
                this.uploadReady = true;
            } else {
                this.fileUploadImage = null;
                this.datatables.photoUrl = null;
                if (this.devices.length > 0) {
                    var deviceId = this.devices[0].deviceId;
                    this.deviceId = deviceId;
                    this.camera = deviceId;
                    this.$refs.webcam.start();                    
                } else {
                    alert(this.t('Webcam tidak aktif'));
                }
            }
            this.cameraon = !this.cameraon;
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        browseImage() {
            this.$refs.imgupload.click();
        },
        setImage(event) {
            this.fileUploadImage = event.target.files[0];
            this.datatables.photoUrl = window.URL.createObjectURL(this.fileUploadImage);
            this.uploadReady = true;
        },
        dataURLtoFile(dataURL, filename) {
            let arr = dataURL.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        },
        uploadImage() {
            var vue = this;
            if (vue.ajaxProcess) {
                return;
            }
            
            var formData = new FormData();
            if (this.fileUploadImage == null) {
                formData.append('blob', this.dataURLtoFile(this.datatables.photoUrl, 'usethis.jpg'));
            } else {
                formData.append('blob', this.fileUploadImage);
            }
            
            var requestOptions = {
                method: 'POST',
                body:formData
            };
            
            vue.ajaxProcess = true;
            fetch(process.env.VUE_APP_WEB_API + 'api/upload-profile-image', requestOptions)
            .then(async response => {
                var result = await response.json();
                if (result.success) {
                    vue.snackbar = true;
                    vue.snackbartext = vue.$t('Foto profile berhasil di ubah');
                }
                vue.ajaxProcess = false;
                vue.uploadReady = false;
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        },
        passwordeye() {
            this.typepasswordnya = (this.typepasswordnya == 'password' ? 'text' : 'password');
            this.iconpassword = (this.iconpassword == 'fa-eye-slash' ? 'fa-eye' : 'fa-eye-slash');
        },
        passwordeye2() {
            this.typepasswordnya2 = (this.typepasswordnya2 == 'password' ? 'text' : 'password');
            this.iconpassword2= (this.iconpassword2 == 'fa-eye-slash' ? 'fa-eye' : 'fa-eye-slash');
        },
        saveprofile() {
            var vue = this;
            vue.$refs.formProfile.validate();
            if (vue.ajaxProcess || !vue.validProfile) {
                return;
            }
            
            vue.profile_msg_error = '';
            vue.ajaxProcess = true;
            vue.errorMessage = '';
            var parameters = {noAnggota: vue.noanggota, fullName: vue.fullname
            ,hp:vue.hp
            ,address:vue.address
            ,birthaddress:vue.birthaddress
            ,birthdate:vue.tgllahir.val
            ,identitastype:vue.identityOptionValue
            ,nomoridentitas:vue.nomoridentitas
            ,pekerjaan:vue.pekerjaanOptionValue
            ,city:vue.datatables.city
            ,provinsi:vue.datatables.province
            ,phone:vue.datatables.phone
            ,sex_id:vue.sexOptionValue
            ,agama_id:vue.agamaOptionValue
            ,pendidikan_id:vue.pendidikanOptionValue
            };
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parameters)
            };
            
            fetch(process.env.VUE_APP_WEB_API + 'api/update-profile', requestOptions)
            .then(async response => {
                const data = await response.json();
                var message = vue.$t(data.message);
                if (data.success) {
                    vue.snackbar = true;
                vue.snackbartext = vue.$t('Profile disimpan');
                } else {
                    vue.profile_msg_error = vue.$t(message);
                }
                vue.ajaxProcess = false;
            })
            .catch(error => {
                vue.ajaxProcess = false;
                alert('Refresh this page' + error);
            });
        },
        simpanPassword() {
            var vue = this;
            vue.$refs.formPassword.validate();
            if (vue.ajaxProcess || !vue.validPassword) {
                return;
            }
            vue.password_msg_error = '';
            if (vue.pass != vue.pass2) {
                vue.password_msg_error = vue.$t('Password and Re-Password is not equal');
                return;
            }
            
            var parameters = {pass:sha1(vue.pass)};
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parameters)
            };
            
            fetch(process.env.VUE_APP_WEB_API + 'api/update-profile-password', requestOptions)
            .then(async response => {
                const data = await response.json();
                var message = vue.$t(data.message);
                if (data.success) {
                    vue.snackbar = true;
                    vue.snackbartext = vue.$t('Password diganti');
                } else {
                    vue.profile_msg_error = vue.$t(message);
                }
                vue.ajaxProcess = false;
            })
            .catch(error => {
                vue.ajaxProcess = false;
                alert('Refresh this page' + error);
            });
        }
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        'tgllahir.val' (val) {
            this.tgllahir.text = this.$parent.formatDate(val);
        },
    },
    mounted() {
        var vue = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        
        fetch(process.env.VUE_APP_WEB_API + 'api/register-options', requestOptions)
        .then(async response => {
            const data = await response.json();
            vue.identityoptions = data.identitas_options;
            vue.pekerjaanoptions = data.job_options;
            vue.agamaoptions = data.agama_options;
            vue.sexoptions = data.sex_options;
            vue.pendidikanoptions = data.pendidikan_options;
            
            vue.ajaxProcess = false;
            
            this.refreshTable(); 
        })
        .catch(error => {
            vue.ajaxProcess = false;
            alert('Refresh this page' + error);
        });
        
        
    }
  }
</script>
